var render = function render(_c,_vm){return _c('div',{staticClass:"product-price"},[(!_vm.props.product.warehousePromo)?_c('div',{staticClass:"price"},[_c('div',{staticClass:"price_block"},[(_vm.props.product.priceDisplay)?_c('div',{staticClass:"cur-price"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceDisplay, "currency"))+" "),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?_c('span',{staticClass:"weight-unit"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay)+" ")]):_vm._e()]):_vm._e()])]):(
      _vm.props.product.warehousePromo &&
        _vm.props.product.warehousePromo.view.cssClass.indexOf(
          'promo_buono B2E'
        ) > -1
    )?_c('div',{class:_vm.props.product.warehousePromo.view.cssClass + ' price'},[_c('div',{staticClass:"price_block"},[(_vm.props.product.priceDisplay)?_c('div',{staticClass:"cur-price"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceDisplay, "currency"))+" "),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?_c('span',{staticClass:"weight-unit"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"product-badge",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.header)}})]):_c('div',{staticClass:"warehouse-promo-price"},[(_vm.props.product.warehousePromo.view.permanent)?_c('div',{staticClass:"promo_permanent",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.permanent)}}):_vm._e(),(
        _vm.props.product.warehousePromo.view.bubble &&
          (_vm.props.product.warehousePromo.warehousePromoTypeId === 1 ||
            _vm.props.product?.price != _vm.props.product?.priceDisplay)
      )?_c('div',{staticClass:"promo_bubble",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.bubble)}}):_vm._e(),_c('div',{staticClass:"product_promo",class:_vm.props.product.warehousePromo.view.cssClass},[_c('div',{staticClass:"promo_header"},[_c('span',{staticClass:"promo_content",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.header)}})]),_c('div',{staticClass:"promo_body price-font"},[_c('span',{staticClass:"promo_content",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.body)}})]),_c('div',{staticClass:"promo_footer",domProps:{"innerHTML":_vm._s(_vm.props.product.warehousePromo.view.footer)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }